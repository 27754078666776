@keyframes pulse-animation {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

@keyframes wave-animation {
  0% {
    transform: rotateZ(15deg);
  }

  100% {
    transform: rotateZ(25deg);
  }
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline:  auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.right-0 {
  right: 0;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.-mb-8 {
  margin-bottom: -2rem;
}

.mb-0 {
  margin-bottom: 0;
}

.grid {
  display: grid;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.items-center {
  align-items: center;
}

.justify-items-center {
  justify-items: center;
}

.gap-8 {
  gap: 2rem;
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(1rem * var(--tw-space-y-reverse) );
}

.bg-neutral-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(23 23 23 / var(--tw-bg-opacity) );
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.text-center {
  text-align: center;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-medium {
  font-weight: 500;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity) );
}

:root {
  color-scheme: dark;
}

body, html {
  color: #eee;
  background-color: #171717;
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  font-weight: 400;
}

body {
  background-image: url("blob-bottom.b250c870.png");
  background-position: 0 100%;
  background-repeat: no-repeat;
}

a {
  font-weight: bolder;
}

a:hover {
  text-decoration: underline #0adef0;
}

#website-header {
  z-index: 70;
  width: 100%;
  height: 80px;
  padding: 15px 20px;
  position: absolute;
  top: 0;
  left: 0;
}

#website-header > a {
  display: inline-block;
}

#website-header img {
  height: 50px;
  position: relative;
  top: -2px;
}

#website-main {
  padding-top: 80px;
  overflow: hidden;
}

#website-footer {
  mix-blend-mode: difference;
  margin-top: 20px;
  padding: 20px;
}

.blob-right {
  max-width: 75%;
  min-width: 60%;
  z-index: 0;
  top: -150px;
}

@media (max-width: 767px) {
  .blob-right {
    width: 120%;
    max-width: 120%;
    top: -100px;
  }
}

#main-experience {
  width: 100%;
  height: 80vh;
  background-color: #000;
}

.metahand-container {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: normal;
  position: relative;
  overflow: hidden;
}

.metahand-container input, .metahand-container select, .metahand-container button {
  font-family: inherit;
}

.metahand-container > #metahand-stats > * {
  z-index: 60 !important;
  top: 80px !important;
  left: 10px !important;
}

.metahand-container > .app-canvas, .metahand-container > .video-container.fullscreen-under, .metahand-container > .full-message {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.metahand-container > .app-canvas {
  z-index: 10;
  width: 100% !important;
  height: 100% !important;
}

.metahand-container > .video-container.fullscreen-under {
  z-index: 8;
}

.metahand-container > .video-container.floating-above {
  z-index: 11;
  width: 25vw;
  height: 25vw;
  max-width: 128px;
  max-height: 128px;
  background-color: #171717;
  border: 1px solid #333;
  border-radius: 5px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  overflow: hidden;
  box-shadow: 0 10px 10px #00000040;
}

.metahand-container > .full-message {
  z-index: 21;
  box-sizing: border-box;
  color: #eee;
  text-align: center;
  text-shadow: 1px 1px #000;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-size: 1.25rem;
  line-height: 1.5em;
  transition: opacity .75s ease-in-out;
  display: flex;
}

.metahand-container > div.full-message {
  pointer-events: none;
}

.metahand-container > .user-action-btn {
  z-index: 50;
  cursor: pointer;
  background-color: #00000040;
  border: none;
  flex-flow: column;
}

.metahand-container > .user-action-btn > span {
  border-radius: 10px;
  display: inline-block;
}

.metahand-container > .user-action-btn > .txt-details {
  margin-bottom: 20px;
  font-size: 1.5rem;
  line-height: 1.25em;
}

.metahand-container > .user-action-btn > .txt-button {
  color: #222;
  text-shadow: none;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 20px 40px;
  font-size: 1.75rem;
  line-height: 1.125em;
  animation: pulse-animation .75s ease-in-out infinite alternate;
}

.metahand-container > #metahand-poses {
  z-index: 30;
  width: 100%;
  box-sizing: border-box;
  color: #eee;
  pointer-events: none;
  justify-content: center;
  padding: 20px;
  font-family: sans-serif;
  font-size: 3rem;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.metahand-container > #metahand-poses > span {
  width: 1em;
  height: 1em;
  text-align: center;
  margin: 0 20px;
}

.metahand-container > #metahand-version {
  z-index: 20;
  color: #eee;
  text-shadow: 1px 1px #000;
  opacity: 0;
  padding: 5px;
  font-family: monospace;
  font-size: .5rem;
  position: absolute;
  bottom: 0;
  left: 0;
}

.metahand-container > #metahand-version:hover {
  opacity: 1;
}

.metahand-container > #metahand-loader {
  height: 100%;
  font-size: 1.5rem;
  line-height: 1.5em;
  top: 0%;
}

body.full-screen-demo-mode #website-main {
  padding-top: 0;
}

body.full-screen-demo-mode #main-experience {
  height: 100vh;
}

body.god-mode .metahand-container > .app-canvas {
  pointer-events: none;
  position: fixed;
}

body.god-mode .metahand-container > .video-container.floating-above {
  position: fixed;
}

body.god-mode .metahand-container .hand-position-tuto, body.god-mode .metahand-container > .user-action-btn, body.god-mode .metahand-container > #metahand-loader {
  z-index: 9 !important;
}

body.god-mode .metahand-container .hand-position-tuto {
  position: fixed !important;
}

body.god-mode .metahand-container > #metahand-poses {
  position: fixed;
}

.dg.ac {
  z-index: 40 !important;
}

.video-container {
  justify-content: center;
  align-items: center;
  display: flex;
}

.video-container video {
  width: 100%;
  height: 100%;
  transition: object-position .5s ease-in-out;
}

.video-container video.invert-horiz {
  transform: scaleX(-1);
}

.video-container video.size-contain {
  object-fit: contain;
}

.video-container video.size-cover {
  object-fit: cover;
}

.playlist-controls {
  z-index: 8;
  width: 59vw;
  max-width: 400px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 20px;
  left: 20px;
  overflow: hidden;
}

.playlist-controls > button {
  width: 50%;
  text-align: center;
  color: #fff;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  background-color: #0003;
  border: 1px solid #ffffff80;
  padding: 10px;
  font-size: 1.25rem;
}

.playlist-controls > button.left {
  border-right-width: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.playlist-controls > button.right {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.playlist-controls > button:hover {
  background-color: #0006;
  border-color: #fff;
}

.hand-position-tuto {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  -webkit-user-select: none;
  user-select: none;
  background-color: #1119;
  overflow: hidden;
  z-index: 10 !important;
  flex-wrap: wrap !important;
}

.hand-position-tuto > .hand-anim {
  width: 300px;
  height: 300px;
  pointer-events: none;
  position: relative;
  top: -5%;
}

.hand-position-tuto > .hand-anim img {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  bottom: 0%;
  left: 0%;
}

.hand-position-tuto > .hand-anim img.hand-wave {
  transform-origin: 60% 100%;
  animation: wave-animation .75s ease-in-out infinite alternate;
}

.hand-position-tuto > .hand-anim.rear-facing img.hand-phone {
  z-index: 99;
  left: 20%;
}

.hand-position-tuto > .hand-anim.rear-facing img.hand-wave {
  z-index: 98;
  filter: brightness(85%);
  left: -20%;
}

.hand-position-tuto > .hand-anim.front-facing img.hand-phone {
  z-index: 98;
  filter: brightness(85%);
  bottom: 5%;
  left: 16%;
  transform: scale(75%);
}

.hand-position-tuto > .hand-anim.front-facing img.hand-wave {
  z-index: 99;
  left: -11%;
}

.hand-position-tuto > .hand-anim .hand-instructions {
  z-index: 999;
  width: 100%;
  text-align: center;
  margin-top: 10px;
  position: absolute;
  top: 100%;
  left: 0%;
}

@media (max-width: 600px), (max-height: 600px) {
  .hand-position-tuto > .hand-anim {
    width: 200px;
    height: 200px;
  }
}

@media (min-width: 768px) {
  .md\:order-1 {
    order: 1;
  }

  .md\:mb-40 {
    margin-bottom: 10rem;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1280px) {
  .xl\:-mb-16 {
    margin-bottom: -4rem;
  }
}

/*# sourceMappingURL=index.111d29a5.css.map */
